<template>
  <div class="layout-wrapper layout-content-navbar">
    <div class="layout-container">
      <!-- Menu -->

      <aside
        id="layout-menu"
        class="layout-menu menu-vertical menu bg-menu-theme"
      >
        <div class="app-brand demo">
          <a href="/" class="app-brand-link">
            <span class="app-brand-logo demo me-1">
              <span style="color: var(--bs-primary)">
                <svg
                  width="30"
                  height="24"
                  viewBox="0 0 250 196"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.3002 1.25469L56.655 28.6432C59.0349 30.1128 60.4839 32.711 60.4839 35.5089V160.63C60.4839 163.468 58.9941 166.097 56.5603 167.553L12.2055 194.107C8.3836 196.395 3.43136 195.15 1.14435 191.327C0.395485 190.075 0 188.643 0 187.184V8.12039C0 3.66447 3.61061 0.0522461 8.06452 0.0522461C9.56056 0.0522461 11.0271 0.468577 12.3002 1.25469Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.077704"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 65.2656L60.4839 99.9629V133.979L0 65.2656Z"
                    fill="black"
                  />
                  <path
                    opacity="0.077704"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0 65.2656L60.4839 99.0795V119.859L0 65.2656Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M237.71 1.22393L193.355 28.5207C190.97 29.9889 189.516 32.5905 189.516 35.3927V160.631C189.516 163.469 191.006 166.098 193.44 167.555L237.794 194.108C241.616 196.396 246.569 195.151 248.856 191.328C249.605 190.076 250 188.644 250 187.185V8.09597C250 3.64006 246.389 0.027832 241.935 0.027832C240.444 0.027832 238.981 0.441882 237.71 1.22393Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.077704"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M250 65.2656L189.516 99.8897V135.006L250 65.2656Z"
                    fill="black"
                  />
                  <path
                    opacity="0.077704"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M250 65.2656L189.516 99.0497V120.886L250 65.2656Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.2787 1.18923L125 70.3075V136.87L0 65.2465V8.06814C0 3.61223 3.61061 0 8.06452 0C9.552 0 11.0105 0.411583 12.2787 1.18923Z"
                    fill="currentColor"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.2787 1.18923L125 70.3075V136.87L0 65.2465V8.06814C0 3.61223 3.61061 0 8.06452 0C9.552 0 11.0105 0.411583 12.2787 1.18923Z"
                    fill="white"
                    fill-opacity="0.15"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M237.721 1.18923L125 70.3075V136.87L250 65.2465V8.06814C250 3.61223 246.389 0 241.935 0C240.448 0 238.99 0.411583 237.721 1.18923Z"
                    fill="currentColor"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M237.721 1.18923L125 70.3075V136.87L250 65.2465V8.06814C250 3.61223 246.389 0 241.935 0C240.448 0 238.99 0.411583 237.721 1.18923Z"
                    fill="white"
                    fill-opacity="0.3"
                  />
                </svg>
              </span>
            </span>
            <span class="app-brand-text demo menu-text fw-semibold ms-2"
              >AGRO FLOW</span
            >
          </a>

          <a
            href="javascript:void(0);"
            class="layout-menu-toggle menu-link text-large ms-auto"
          >
            <i class="menu-toggle-icon d-xl-block align-middle"></i>
          </a>
        </div>

        <div class="menu-inner-shadow"></div>

        <ul class="menu-inner py-1">
          <!-- Dashboards -->
          <li class="menu-item active open">
            <a href="javascript:void(0);" class="menu-link">
              <i class="menu-icon tf-icons ri-home-smile-line"></i>
              <div data-i18n="Dashboards">Dashboards</div>
              <div class="badge bg-danger rounded-pill ms-auto">NEW</div>
            </a>
            <ul class="menu-sub">
              <li class="menu-item" :class="{ active: $route.path === '/' }">
                <router-link to="/" class="menu-link">
                  <div data-i18n="Analytics">Analytics</div>
                </router-link>
              </li>
              <li
                class="menu-item"
                :class="{ active: $route.path === '/sales' }"
              >
                <router-link to="/sales" class="menu-link">
                  <div data-i18n="eCommerce">Sales</div>
                </router-link>
              </li>
              <li
                class="menu-item"
                :class="{ active: $route.path === '/truck-follow-up' }"
              >
                <router-link to="/truck-follow-up" class="menu-link">
                  <div data-i18n="Logistics">Truck Follow Up</div>
                </router-link>
              </li>
              <li
                class="menu-item"
                :class="{ active: $route.path === '/complains' }"
              >
                <router-link to="/complains" class="menu-link">
                  <div data-i18n="Academy">Complains</div>
                </router-link>
              </li>
              <li
                class="menu-item"
                :class="{ active: $route.path === '/invoice-payments' }"
              >
                <router-link to="/invoice-payments" class="menu-link">
                  <div data-i18n="Academy">Invoice Payments</div>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="menu-header mt-7">
            <span class="menu-header-text">Logistics &amp; Pages</span>
          </li>
          <!-- Apps -->
          <li
            class="menu-item"
            :class="{ active: $route.path === '/record-sales' }"
          >
            <router-link to="/record-sales" class="menu-link">
              <i class="menu-icon tf-icons ri-mail-open-line"></i>
              <div data-i18n="Email">Record Sales</div>
            </router-link>
          </li>
          <li
            class="menu-item"
            :class="{ active: $route.path === '/record-expenses' }"
          >
            <router-link to="/record-expenses" class="menu-link">
              <i class="menu-icon tf-icons ri-wechat-line"></i>
              <div data-i18n="Chat">Record Expenses</div>
            </router-link>
          </li>

          <!-- Forms & Tables -->
          <li class="menu-header fw-medium mt-4">
            <span class="menu-header-text">Reports</span>
          </li>
          <!-- Forms -->
          <!-- Form Validation -->
          <li
            class="menu-item"
            :class="{ active: $route.path === '/sales-report' }"
          >
            <router-link to="/sales-report" class="menu-link">
              <i class="menu-icon tf-icons ri-checkbox-multiple-line"></i>
              <div data-i18n="Form Validation">Sales Report</div>
            </router-link>
          </li>
          <li
            class="menu-item"
            :class="{ active: $route.path === '/expense-report' }"
          >
            <router-link to="/expense-report" class="menu-link">
              <i class="menu-icon tf-icons ri-table-alt-line"></i>
              <div data-i18n="Tables">Expenses Report</div>
            </router-link>
          </li>
          <li class="menu-item">
            <a href="/follow-up-report" target="_blank" class="menu-link">
              <i class="menu-icon tf-icons ri-grid-line"></i>
              <div data-i18n="Datatables">Truck Follow up Report</div>
            </a>
          </li>
        </ul>
      </aside>
      <!-- / Menu -->

      <!-- Layout container -->
      <div class="layout-page">
        <!-- Navbar -->

        <nav
          class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
          id="layout-navbar"
        >
          <div
            class="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0 d-xl-none"
          >
            <a class="nav-item nav-link px-0 me-xl-6" href="javascript:void(0)">
              <i class="ri-menu-fill ri-24px"></i>
            </a>
          </div>

          <div
            class="navbar-nav-right d-flex align-items-center"
            id="navbar-collapse"
          >
            <!-- Search -->
            <div class="navbar-nav align-items-center">
              <div class="nav-item d-flex align-items-center">
                <i class="ri-search-line ri-22px me-2"></i>
                <input
                  type="text"
                  class="form-control border-0 shadow-none"
                  placeholder="Search..."
                  aria-label="Search..."
                />
              </div>
            </div>
            <!-- /Search -->

            <ul class="navbar-nav flex-row align-items-center ms-auto">
              <!-- Place this tag where you want the button to render. -->
              <li class="nav-item lh-1 me-4">
                <a
                  class="github-button"
                  href="#"
                  data-icon="octicon-star"
                  data-size="large"
                  data-show-count="true"
                  aria-label="Star themeselection/AGRO FLOW-bootstrap-html-admin-template-free on GitHub"
                  >SHYAKA Eric Mushimire</a
                >
              </li>

              <!-- User -->
              <li class="nav-item navbar-dropdown dropdown-user dropdown">
                <a
                  class="nav-link dropdown-toggle hide-arrow p-0"
                  href="javascript:void(0);"
                  data-bs-toggle="dropdown"
                >
                  <div class="avatar avatar-online">
                    <img
                      src="assets/img/avatars/1.png"
                      alt
                      class="w-px-40 h-auto rounded-circle"
                    />
                  </div>
                </a>
                <ul class="dropdown-menu dropdown-menu-end mt-3 py-2">
                  <li>
                    <a class="dropdown-item" href="#">
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 me-2">
                          <div class="avatar avatar-online">
                            <img
                              src="assets/img/avatars/1.png"
                              alt
                              class="w-px-40 h-auto rounded-circle"
                            />
                          </div>
                        </div>
                        <div class="flex-grow-1">
                          <h6 class="mb-0 small">John Doe</h6>
                          <small class="text-muted">Admin</small>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div class="dropdown-divider"></div>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/profile-account">
                      <i class="ri-user-3-line ri-22px me-2"></i>
                      <span class="align-middle">My Profile</span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="/roles-permissions">
                      <i class="ri-key-fill ri-22px me-2"></i>
                      <span class="align-middle">Roles & Permissions</span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      <i class="ri-settings-4-line ri-22px me-2"></i>
                      <span class="align-middle">Settings</span>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      <span class="d-flex align-items-center align-middle">
                        <i
                          class="flex-shrink-0 ri-file-text-line ri-22px me-3"
                        ></i>
                        <span class="flex-grow-1 align-middle">Billing</span>
                        <span
                          class="flex-shrink-0 badge badge-center rounded-pill bg-danger h-px-20 d-flex align-items-center justify-content-center"
                          >4</span
                        >
                      </span>
                    </a>
                  </li>
                  <li>
                    <div class="dropdown-divider"></div>
                  </li>
                  <li>
                    <div class="d-grid px-4 pt-2 pb-1">
                      <a class="btn btn-danger d-flex" href="/login">
                        <small class="align-middle">Logout</small>
                        <i class="ri-logout-box-r-line ms-2 ri-16px"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </li>
              <!--/ User -->
            </ul>
          </div>
        </nav>

        <!-- / Navbar -->

        <!-- Content wrapper -->
        <div class="content-wrapper">
          <!-- Content -->

          <router-view name="dashboard"></router-view>
          <!-- / Content -->

          <!-- Footer -->
          <footer class="content-footer footer bg-footer-theme">
            <div class="container-xxl">
              <div
                class="footer-container d-flex align-items-center justify-content-between py-4 flex-md-row flex-column"
              >
                <div class="text-body mb-2 mb-md-0">
                  © 2024 , made with
                  <span class="text-danger"
                    ><i class="tf-icons ri-heart-fill"></i
                  ></span>
                  by
                  <a href="#" target="_blank" class="footer-link"
                    >SHYAKA Eric Mushimire</a
                  >
                </div>
              </div>
            </div>
          </footer>
          <!-- / Footer -->

          <div class="content-backdrop fade"></div>
        </div>
        <!-- Content wrapper -->
      </div>
      <!-- / Layout page -->
    </div>

    <!-- Overlay -->
    <div class="layout-overlay layout-menu-toggle"></div>
  </div>
  <!-- / Layout wrapper -->
</template>
